import { HeadFC, navigate } from 'gatsby';
import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import FarewellCard from '../../components/FarewellCard.tsx';
import { Body2 } from '../../components/Fonts/Body';
import { H4 } from '../../components/Fonts/HTags';
import { Subtitle } from '../../components/Fonts/Subtitle';
import Footer from '../../components/Footer';
import { Line } from '../../components/Line';
import { Loading } from '../../components/Loading';
import NavBar from '../../components/Nav';
import { Seo } from '../../components/Seo';
import { Spacer } from '../../components/Spacer';
import { View } from '../../components/View';
import { fontWeightType } from '../../constants/enums';
import { useAuth } from '../../hooks/useAuth';
import Base from '../../layout/Base';
import server from '../../services/fetchApi';
import { sentryCaptureException } from '../../services/sentry';

const UserInfo = styled.nav`
  background: transparent;
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.colors.mainAguaColor};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
`;

const PictureUser = styled.div`
  background-color: ${({ theme }) => theme.colors.mainAguaColor};
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.simpleWhite};
  border-radius: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    flex-direction: row;
  }
`;

const Farewell = styled.p`
  font-size: 12px;
`;

const CancelPlansPage = () => {
  useAuth();
  const [currentUser, setCurrentUser] = useState<any>();
  const [loading, setLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const response = await server.get('api/v1/users/get_user');
      if (typeof window !== 'undefined') {
        localStorage.setItem('endDate', response.data?.active_subscription?.current_period_end);
        if (response.data?.active_subscription?.plan?.name) {
          localStorage.setItem('name', response.data?.active_subscription?.plan?.name);
        }

        if (response.data?.active_subscription?.plan?.amount) {
          localStorage.setItem('price', response.data?.active_subscription?.plan?.amount);
        }
      }
      setCurrentUser(response.data);
    } catch (error) {
      sentryCaptureException(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const cancelPlan = async () => {
    try {
      setLoading(true);
      const canceled = await server.post('/api/v1/plans/cancel_subscription');
      if (canceled.statusText === 'OK') {
        const params = new URLSearchParams(location?.search);
        const token = params?.get('token');
        navigate(`/canceled-plan`);
      }
    } catch (error) {
      sentryCaptureException(error);
    } finally {
      setLoading(false);
    }
  };

  const { first_name, last_name, email } = currentUser?.user || {
    first_name: '',
    last_name: '',
    email: '',
  };

  if (loading) {
    return (
      <Loading
        onPress={() => null}
        mainTitle="Por favor espere un momento..."
        type="loading"
        buttonLabel=""
      />
    );
  }

  return (
    <Base>
      <NavBar>
        <UserInfo>
          <PictureUser>
            {first_name[0]}
            {last_name[0]}
          </PictureUser>
          <Spacer size="8" />
          <div>
            <Subtitle color="simpleWhite" fontWeight={fontWeightType.Bold}>
              {first_name} {last_name}
            </Subtitle>
            <Spacer size="3" />
            <Body2 color="simpleWhite" fontWeight={fontWeightType.Medium}>
              {email}
            </Body2>
          </div>
        </UserInfo>
      </NavBar>
      <View>
        <Spacer size="30" />
        <H4 color="grayBlack" fontWeight={fontWeightType.Bold}>
          Cancelar tu paquete de carpediem es fácil
        </H4>
        <Spacer size="18" />
        <RowContainer>
          <Farewell>
            Gracias por hacer parte del team carpe diem, estamos muy contentos que hayas estado todo
            este tiempo con nosotros preparándote para siguientes pasos con las especializaciones y
            con tus exámenes de residencia. ¡Estaremos esperándote próximamente! Completa la
            cancelación de tu paquete a continuación:
          </Farewell>
        </RowContainer>
        <Spacer size="18" />
        <FarewellCard date={currentUser?.active_subscription?.current_period_end} />
        <Spacer size="35" />
        <Line />
        <Spacer size="35" />
        <RowContainer>
          <Button
            customWidth="40%"
            customHeight="44px"
            customBg="white"
            onClick={() => navigate(-1)}>
            Regresar
          </Button>
          <Button
            customWidth="40%"
            customHeight="44px"
            customBg="aqua"
            onClick={() => cancelPlan()}>
            Cancelar paquete
          </Button>
        </RowContainer>
        <Spacer size="35" />
        <Line />
        <Spacer size="35" />
      </View>
      <Footer />
    </Base>
  );
};

export default CancelPlansPage;

export const Head: HeadFC = () => <Seo title="Carpediem MediAPP - Paquete" />;
